module.exports = [{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-QTCT4S5RY6","includeInDevelopment":true},
    },{
      plugin: require('/home/circleci/repo/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"aemc-esb-post-2025-market-design","short_name":"esb-post-2025","start_url":"http://esb-post2025-market-design.aemc.gov.au/","background_color":"#ffffff","theme_color":"#EC4E20","display":"standalone","icon":"/home/circleci/repo/src/img/esb-simple.svg"},
    },{
      plugin: require('/home/circleci/repo/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
